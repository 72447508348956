<script>
import MainBackground from '@/assets/images/004.jpg'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import { loadRemarkableLabs } from './RemarkableLabs'

import InfoCardVue from '@/components/ui/InfoCard.vue'
import LoadingVue from '@/components/ui/Loading.vue'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { InfoCardVue, LoadingVue, ImageBackgroundVue },
  data() {
    return {
      isLoading: false,
      labList: [],
      src: MainBackground
    }
  },
  setup() {},
  created() {},
  async mounted() {
    if (this.getStoredLabList().length === 0) {
      const loadedLabList = await this.loadLabList()
      this.$store.dispatch('labStore/SetRemarkableLabList', loadedLabList)
    }

    this.labList = this.getStoredLabList()
  },

  unmounted() {},

  methods: {
    LanguageConverter,

    async loadLabList() {
      this.isLoading = true

      const remarkables = await loadRemarkableLabs()

      this.isLoading = false

      return remarkables
    },

    getStoredLabList() {
      return this.$store.state.labStore.remarkableLabList.map((item) => {
        return {
          src: item.leadResearcherImgUrl,
          data: {
            title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
            subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
            infoList: [
              {
                head: LanguageConverter({ en: 'Position', kr: '직위' }),
                // 'Lead Researcher'
                desc: LanguageConverter({ en: item.leadResearcherPosition, kr: item.leadResearcherPosition })
              },
              {
                head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
                desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
              },
              {
                head: LanguageConverter({ en: 'MainField', kr: '분야' }),
                desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
              },
              {
                head: LanguageConverter({ en: 'Keywords', kr: '키워드' }),
                desc: LanguageConverter({
                  en: item.keywordEn
                    ?.split(',')
                    .slice(0, 3)
                    .reduce((acc, cur) => acc + ',' + cur),
                  kr: item.keyword
                    ?.split(',')
                    .slice(0, 3)
                    .reduce((acc, cur) => acc + ',' + cur)
                })
              }
            ],
            link: `/lab/${item.labNo}`
          }
        }
      })
    },

    cardClickHandler({ link }) {
      this.$router.push(link)
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <article class="wf__remarkable-info wf__full-width">
    <ImageBackgroundVue :src="src">
      <div>
        <h1 class="wf__default-title">
          {{ LanguageConverter({ en: 'Remarkable Labs', kr: '이달의 연구실' }) }}
        </h1>
        <p class="wf__remarkable-info-description">
          {{ LanguageConverter({ en: "This month's attention-grabbing laboratories", kr: '이달에 주목받는 연구실입니다.' }) }}
        </p>
      </div>
    </ImageBackgroundVue>
  </article>
  <section class="wf__remarkable">
    <article class="wf__remarkable-results">
      <div class="wf__remarkable-results">
        <div class="wf__remarkable-results-head">총<span class="emphasized" v-text="labList.length"></span>명</div>
        <div class="wf__remarkable-results-wrapper">
          <div class="wf__remarkable-results-item wf__card" v-for="(lab, i) in labList" :key="i">
            <InfoCardVue :src="lab.src" :data="lab.data" @onClick="cardClickHandler" />
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__remarkable {
  min-height: 700px;
}

.wf__remarkable-info {
  margin-bottom: 2.5em;
  text-align: center;
}
.wf__remarkable-info-title {
  font-weight: bold;
  font-size: 2.5em;
}
.wf__remarkable-info-description {
  margin-top: 0.75em;
  padding: 0 1em;
  font-size: var(--wf-text-16);
}
.wf__remarkable-results-head {
  font-size: var(--wf-text-16);
}

.wf__remarkable-results {
  text-align: left;
}
.emphasized {
  color: var(--wf-primary-color);
  padding: 0 0.25em;
  font-weight: bold;
}
.wf__remarkable-results-wrapper {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;
}
.wf__remarkable-results-item {
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .wf__remarkable-results-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__remarkable-results-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
